@import "./assets/scss/global";

.page {
  min-height: 100vh;
  overflow: hidden;
}

.primary {
  width: 100%                                     ;
  height: 100%;
  min-height: calc(100vh - 70px - 96px);
}

.page-title {
  font-size: 2rem;
  margin: 0;

  @media (min-width: 923px) {
    font-size: 3rem;
  }
}

.main {
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 923px) {
    min-height: calc(100vh - 271px);
  }
}


.header {
  display: flex;
  justify-content: center;
  align-items: center;

  .baroque {
    width: 160px;

    @media (min-width: 923px) {
      position: relative;
      top: 3vh;
      width: 31vw;
    }

    @media (min-width: 1600px) {
      top: 4vh;
      width: 33vw;
    }

  }

  .site-title {
    display: inline-flex;
    justify-content: center;
    flex: 1 1 auto;
    font-family: var(--font-fam-deco);
    font-size: 3rem;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 1;
    margin: 2rem 0 0.5rem 0;
    color: crimson;
    text-align: center;

    @media (min-width: 923px) {
      font-family: var(--font-fam-deco);
      font-size: 4.5vw;
      width: 20vw;
    }
  }

  .r-big {
    display: inline-flex;
    flex: 1 1 auto;
    color: crimson;
    font-family: var(--font-fam-deco);
    text-align: center;
    font-size: 2rem;
    font-weight: 700;

    @media (min-width: 923px) {
      font-family: var(--font-fam-deco);
      font-size: 6rem;
    }
  }
}

.hero {
  position: relative;
  width: 100vw;
  height: 25vh;
  min-height: 200px;
  background-color: black;
  background-image: url("/assets/images/bg-40.jpg");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  animation: toggle-bg 1s 1s forwards;
  transition: 0.35s;
  border-top: 1px solid #333;

  @media (min-width: 923px) {
    height: 45vh;
    padding-bottom: 1rem;
    z-index: 2;
  }

  @media (min-width: 1140px) {
    height: 40vh;
  }

  @media (min-width: 1400px) {
    height: 35vh;
  }
  @media (min-width: 1900px) {
    height: 30vh;
  }

}

@keyframes toggle-bg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.hero--wrapper {
  background-image: url("/assets/images/bg-39.jpg");
  opacity: 1;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
}

// Specific pages 

.about-page {
  min-height: calc(75vh - 70px);
}

.bg-login {

  @media (max-width: 922px) {
    display: none;
  }

  @media (min-width: 923px) {
    position: relative;
    background-color: black;
    border-top: 1px solid #666;
    z-index: 2;
  }
}

.bg-404 {
  background-image: url('./assets/images/404-2.jpg');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
}

.notfound-page {
  min-height: 100%;
  border-top: 1px solid #666;


  .section {
    min-height: 100%;
    display: flex;

    align-items: center;
    justify-content: center;

    @media (min-width: 923px) {
      min-height: calc(100vh - 210px);
      flex-direction: column;
    }
  }

  .box {
    min-height: 100%;
    display: flex;
    align-items: center;

    @media (min-width: 923px) {
      flex-direction: column;
      min-height: calc(100vh - 210px);
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  h1 {
    color: white;
    font-family: var(--font-fam-deco);
    margin: 1rem 0;
  }
  

  .snippet {
    display: none;

    @media (min-width: 923px) {
      display: block;
      background-image: url('./assets/images/shadow.png');
      background-position: center;
    }
  }

  p {
    @media (min-width: 923px) {
      display: block;
      font-family: var(--font-fam-art);
      font-size: 1.125rem;
      font-family: var(--font-fam-alt);
      line-height: 1.5;
      font-weight: 400;
      color: white;
      margin-top: 0;
    }
  }

  .button-home {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;

    @media (min-width: 923px) {
      height: 50px;
    }
  }

  .topnav--wrapper+.notfound-page {
    border-top: 1px solid #555;
    width: 100vw;
  }

  .topnav--wrapper+.notfound-page>.main {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 166px);
    max-width: 1140px;
    margin: 0 auto;
  }
}

.credits-page,
.disclaimer-page {
  .bg-m {
    @media (max-width: 922px) {
      display: none;
    }

    @media (min-width: 923px) {
      background-color: #f3f5f7;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 25vh;
    }
  }

  .box {
    padding: 1rem;

    h2,
    h2,
    h3 {
      margin: 0 0 1rem;
    }

    ul,
    li,
    ul li {
      margin: 0;
      padding-left: 0;
      text-indent: 0;
      line-height: 1.5;
    }

    li {
      display: inline-block;
      padding-left: 2rem;
      padding-bottom: 0.5rem;

      &::before {
        content: '— ';
      }
    }

  }
}

.credits-page .bg-m {
  @media (min-width: 923px) {
    background-image: url('./assets/images/bg-heart.png');
  }
}

.disclaimer-page .bg-m {
  @media (min-width: 923px) {
    background-image: url('./assets/images/risk.jpg');
  }
}
