  .edit-page {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    
    &.vertical {
      
      .secondary-content {
        display: none;
      }

      .sec-toggle {
        display: none;
      }


    }

    &.horizontal {

      .secondary-content {
        position: relative;
        display: none;

        @media (min-width: 1200px) {
          display: flex;
          flex-direction: column;
          height: 100vh;
          flex: 0 1 calc(100vw - 100vh + 70px);
          width: calc(100vw - 100vh + 70px);
          top: 0;
          left: 0;
          transition: left 0.5s ease-in-out;
          z-index: 100;

          &.collapse {
            left: calc(-100vw + 100vh - 70px);
          }
        }

        @media (min-width: 1440px) {
          &.expand {
            flex: 0 0 610px;
            width: 610px;
          }

          &.collapse {
            left: calc(-100vw + 100vh - 70px);
          }
        }
      }

      .primary-content {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        background-color: white;
        transition: left 0.5s ease-in-out, width 0.5s ease-in-out;
        z-index: 60;

        &.expand {
          left: 0;
          width: 100vw;
        }

        &.collapse {
          @media (min-width: 1200px) {
            left: calc(100vw - 100vh + 70px);
            width: calc(100vh - 70px);
          }

          @media (min-width: 1441px) {
            left: 610px;
            width: calc(100vw - 610px);
          }
        }
      }
    }

    .res {
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      height: calc(100vh - 70px);
      background-color: black;
      transition: left 0.5s ease-in-out, width 0.5s ease-in-out;
      z-index: 60;
    }

    .editor {
      position: absolute;
      top: 0;
      left: 0;
      flex-direction: row;
      width: 100%;
      height: 100%;
      z-index: 70;

      .dnd {
        position: relative;
        z-index: 60;
      }
    }

    .artboard {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      transition: left 0.5s ease-in-out, width 0.5s ease-in-out;
      z-index: 60;
    }

    .bg-gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      transition: left 0.5s ease-in-out, width 0.5s ease-in-out;
      z-index: 60;
    }


    .sec-toggle {
      position: absolute;
      top: calc(45vh - 48px);
      width: 48px;
      height: 96px;

      &:hover {
        cursor: pointer;
      }

      @media (min-width: 1200px) {
        &.look-left {
          transform: scale(1);
          left: calc(100vw - 100vh + 70px - 48px);
        }

        &.look-right {
          left: calc(100vw - 100vh + 70px);
          transform: scale(-1);
        }
      }

      @media (min-width: 1440px) {
        &.look-left {
          left: 564px;
        }

        &.look-right {
          left: calc(100vw - 100vh + 70px);
        }
      }

      .icon {
        position: relative;
        left: 42px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 96px;
        transform: scale(-1.5);
        z-index: 120;

        svg {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          fill: black;
          z-index: 120;
        }
      }

    }

    .asides {
      width: 100%;
      padding: 1rem;

      @media (min-width: 1200px) {
        padding: 2rem;
      }

      aside {
        width: 100%;
      }
    }

    .tagline {

      @media (min-width: 1200px) {
        font-weight: 200;
        margin: 0 0 3rem 0;
        letter-spacing: -2px;
      }
    }

    .features {

      @media (min-width: 1200px) {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        row-gap: 1rem;
        column-gap: 1rem;

        .feature-icon {
          display: inline-block;
          width: 64px;
          height: 64px;
        }

        p {
          font-family: var(--font-fam-alt);
          font-size: 1rem;
          font-weight: 400;
          margin: 0;
          color: black;
          margin: 0.5rem 0;
        }
      }

      @media (min-width: 1440px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

    }

    .palettes--wrapper {
      position: absolute;
      top: calc(70px + 1rem);
      left: 50%;
      padding: 1rem;
      z-index: 110;

      .break {
        flex-basis: 100%;
        height: 10px;
      }

      .palettes {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .palette {
          display: block;
          float: left;
          width: 40px;
          height: 40px;
          border: 1px solid white;

          &:hover {
            border: 4px solid white;
            cursor: pointer;
          }

          &.current {
            border: 4px solid aqua;
          }
        }
      }
    }

    .present-text {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      height: 80%;
      transform: translate(-50%, -50%);
      font-family: var(--font-fam-deco);
      line-height: 1.25;
      z-index: 60;

      @media (min-width: 923px) {
        line-height: 1.4;
      }

      .text--wrapper {
        display: inline-flex;
      }

      .text {
        position: relative;
        display: inline-block;
        width: auto;
        min-width: 340px;
        resize: none;
        border: 0;
        overflow: hidden;
        outline: 0;
        background-color: transparent;
        color: white;
        font-size: 1rem;
        line-height: 1.4;
        caret-color: white;
        z-index: 57;
        white-space: pre-wrap;

        @media (min-width: 923px) {
          font-size: 2rem;
          min-width: 600px;
        }

        &.white {
          color: white;
        }

        &.black {
          color: black;
        }

        &.size-10 {
          font-size: 1rem;
        }

        &.size-15 {
          font-size: 1.5rem;
        }

        &.size-20 {
          font-size: 2rem;
        }

        &.size-25 {
          font-size: 2.5rem;
        }

        &.size-30 {
          font-size: 3rem;
        }

        &.size-35 {
          font-size: 3.5rem;
        }

        &.size-40 {
          font-size: 4rem;
        }

        &.size-45 {
          font-size: 4.5rem;
        }

        &.size-50 {
          font-size: 5rem;
        }

        &.size-55 {
          font-size: 5.5rem;
        }

        &.size-60 {
          font-size: 6rem;
        }

        &.size-65 {
          font-size: 6.5rem;
        }

        &.size-70 {
          font-size: 7rem;
        }
      }
    }

    .move-handle,
    .resize-handle {
      display: none;
    }

    .editing {

      .text,
      .author {
        border: 1px dotted lightgray;
        padding: 0.5rem;
      }

      .move-handle {
        position: relative;
        display: inline-block;
        cursor: move;

        &.white svg path {
          fill: white;
        }

        &.black svg path {
          fill: black;
        }
      }

      .resize-handle {
        position: relative;
        left: -6px;
        display: inline-flex;
        align-self: center;
        width: 12px;
        height: 20px;
        background-color: rgba(255, 255, 255, 0.35);
        z-index: 56;
      }
    }


    .author {
      display: inline-block;
      width: min-content;
      font-size: 0.977rem;
      background-color: transparent;
      border: 0;
      resize: none;
      outline: 0;
      caret-color: white;
      color: white;
      overflow: hidden;

      &.white {
        color: white;
      }

      &.black {
        color: black;
      }

      &:empty::placeholder {
        color: transparent;
      }
    }


    .poem:hover .author:empty::placeholder {
      color: unset;
    }

  }