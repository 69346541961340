.start--wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  z-index: 1;
  background-size: contain;
  background-repeat: no-repeat;

  @media (min-width: 923px) {
    max-width: 1140px;
    margin: 0 auto;
    background-image: none;
  }
}

.start {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;

  @media (min-width: 923px) {
    flex: 1 1 auto;
    flex-direction: column;
  }

  h2.tagline {
    font-family: var(--font-fam-cond);
    font-size: 1.25rem;
    line-height: 1.25;
    color: black;
    margin: 0 0 0.5rem 0;

    @media (min-width: 923px) {
      margin: 1.75rem 0 1rem 0;
      font-size: 2rem;
      color: black;
      letter-spacing: -0.5px;
    }
  }

  p {
    margin: 0 0 .5rem;
    font-size: 1.25rem;
  }

  .notice {
    margin: 1rem 0;
    font-family: var(--font-fam-alt);
    font-weight: 300;
    font-size: 1rem;
  
    &>a {
      color: crimson;
    }
  
  }
  
  .form {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;

    @media (min-width: 923px) {
      letter-spacing: -0.25px;
      margin: 1rem auto;
    }
    @media (min-width: 1400px) {
      margin: 1.5rem auto;
    }
    @media (min-width: 1900px) {
      margin: 1rem auto;
    }

    .start-input {
      flex: 1 1 auto;
      position: relative;
      display: inline-flex;
      background-color: white;
      border: 1px solid var(--color-neutral-light);
      width: 120px;
      height: 38px;
      padding: 0.25rem 0.5rem;
  
      @media (min-width: 923px) {
        width: calc(100% - 120px - 120px);
      }
    }

    .lt-select {
      margin: 0 0.5rem;

      @media (min-width: 923px) {
        flex: 0 0 200px;
        min-width: 200px;
        margin: 0 1rem;
      }
    }

    .lt-btn.m {
      display: inline-flex;
      justify-content: center;
      height: 40px;
      width: 120px;

      @media (min-width: 923px) {
        flex: 0 0 200px;
        min-width: 150px;
        width: auto;
        margin-left: 1rem;
      }
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;
  }

  .features {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 923px) {
      margin: 2rem 0 0 0;
      flex-direction: row;
    }

    .feature {
      display: flex;
      width: 100%;

      @media (min-width: 923px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 0 0.5rem;
      }

      .feature-icon {
        flex: 0 0 30%;
        width: 30%;
        margin-top: 1.5rem;

        @media (min-width: 923px) {
          width: 50%;
          margin-top: 0;
        }

        img {
          display: inline-block;
          width: 100%;
        }
      }

      .feature--wrapper {
        flex: 0 0 60%;
        width: 60%;
        margin-left: 5%;

        @media (min-width: 923px) {
          margin: 0 2.5rem 0 0;
          width: 100%;

          &:last-of-type {
            margin: 0;
          }
        }
      }

      h3 {
        font-family: var(--font-fam-deco);
        font-size: 1.5rem;
        font-weight: 500;
        color: crimson;
        margin: 0 0 0.5rem 0;

        @media (min-width: 923px) {
          letter-spacing: -0.5px;
          margin: 1rem 0 0.5rem;
          font-size: 1.75rem;
        }
      }

      p {
        font-family: var(--font-fam-semi);
        font-size: 0.875rem;
        font-weight: 400;

        @media (min-width: 923px) {
          font-size: 1.125rem;
        }
      }
    }
  } 
}

