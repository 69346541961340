.collection {
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  margin: 0 auto;
  padding: 1rem;

  @media (min-width: 923px) {
    padding: 2rem 0;
  }

  .lt-alert {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 0 0;
    padding: 1rem;
    border: 1px solid lightgrey;
    font-family: var(--font-fam-alt);
    font-weight: 300;
    font-size: 1rem;
    margin: 0;

    .lt-btn {
      margin-left: 2rem;
    }

    @media (min-width: 923px) {
      font-size: 1.25rem;
    }

  }

  .lt-flex-grid {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 923px) {
      flex-direction: row;
    }

    &:empty {
      display: none;
    }

    @media (min-width: 923px) {
      max-width: 1140px;
      flex-wrap: wrap;
    }

    h1.section-title {
      font-size: 1.75rem;
      margin: 1.25rem 0.5rem;

      @media (min-width: 923px) {
        font-size: 3rem;
      }
    }

    .token-card {
      display: flex;
      flex-direction: column;
      background-color: white;
      margin-bottom: 1rem;
      width: 100%;

      @media (min-width: 923px) {
        flex: 0 0 260px;
        width: 260px;
        margin-right: 1rem;
        height: 300px;
      }

      .token-topbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.25rem 0.25rem 0;

        @media (min-width: 923px) {
          padding: 0;
        }
      }

      .token-title {
        display: inline-flex;
        font-family: var(--font-fam-alt);
        font-size: 0.875rem;
        width: 80%;
        margin-right: 1rem;
      }

      .header {
        display: flex;
        justify-content: space-between;
        padding: 0 0.25rem 0.5rem;

        @media (min-width: 923px) {
          padding: 0;
        }
      }

      .token-transfer {
        display: flex;
        flex: 1 1 auto;
        padding: 0 0.25rem 0.5rem;

        @media (min-width: 923px) {
          padding: 0 0 0.5rem 0;
        }

        input[type="text"] {
          flex: 1 1 calc(100% - 50px);
          width: calc(100% - 50px);
          display: inline-flex;
          transition: 0.75s;
          margin-left: 0.25rem;
          height: 1.5rem;
          padding: 0.25rem;

          &::placeholder {
            font-size: 0.875rem;
            margin-left: 0;
          }
        }

        .lt-btn {
          flex: 0 0 50px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 1.5rem;
          margin-left: 0.25rem;
          width: 50px;
          padding: 0.25rem 0.5rem;
        }
      }

      .thumbnail {
        width: 100%;
        display: inline-block;
        object-fit: cover;
        overflow: hidden;

        @media (min-width: 923px) {
          flex: 0 0 260px;
          width: 260px;
          height: 260px;
        }
      }

      p {
        font-size: 0.875rem;
        padding-left: 5px;
      }

      a {
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .lt-btn {
    padding: 1rem 2rem;
  }

}