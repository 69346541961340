.topnav--wrapper {
  display: flex;
  position: relative;

  &.mini {
    width: 100%;
    background-color: black;
    z-index: 80;

    .topnav {
      justify-content: flex-start;
      padding-right: 0;
    }
  }

  &.collapse {
    animation: collapse 0.5s ease-out forwards;
  }
}

.topnav {
  position: relative;
  display: flex;
  width: 100%;
  height: 70px;
  background-color: black;
  padding: 0 1rem;

  .fip {
    position: relative;
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
    flex: 1 0 50%;

    .logo {
      position: relative;
      display: inline-flex;
      width: 100%;
      align-items: center;
      font-family: 'Great Vibes', sans-serif;
      font-size: 2rem;
      font-weight: 400;
      color: white;

      .logo-img {
        display: inline-flex;
        margin: -2px .5rem 0 0;
        width: 50px;
        height: 50px;
      }

      .word-play {
        display: none;

        @media (min-width: 1200px) {
          display: inline-flex;
          font-family: 'Great Vibes', sans-serif;
          font-size: 2rem;
          font-weight: 400;
          color: white;
          margin-left: 0.5rem;
          padding-left: 1.25rem;
          border-left: 1px solid #666;
        }

      }

      a {
        display: inline-flex;
        color: white;

        &:hover {
          color: crimson;
        }
      }

      @media (min-width: 923px) {
        font-size: 2rem;
      }

      @media (min-width: 1200px) {
        font-size: 2rem;
      }

    }
  }

  .nav {
    display: flex;
    padding-left: 1rem;
    align-items: center;

    .btn-icon {
      border: 0;
      background-color: transparent;
      display: flex;
      width: 3rem;
      height: 3rem;
      align-items: center;
      color: white;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
      color: white;
      z-index: 20;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .szh-menu {
    background-color: black !important;
  }

  .szh-menu-button {
    position: relative;
    display: flex;
    align-items: center;
    background-color: black;
    color: white;
    border: 0;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 60;
  }
}

