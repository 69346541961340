.drafts-grid {

  .draft--wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 0.5rem 0.25rem;
    width: 190px;
    animation: appear 0.5s ease-out forwards;

    .icon-btn {
      display: inline-flex;
      background-color: var(--color-neutral-lightest);
      color: #333;
      position: relative;
      top: -1rem;

      &:hover {
        cursor: pointer;
        color: crimson;
      }
    }

    .draft {
      display: block;
      width: 100%;
      font-size: 11px;
      padding: 6px;
      color: black;
      background-color: var(--color-neutral-lightest);
      border-radius: 3px;

      &:hover {
        cursor: pointer;
        background-color: aquamarine;
      }
    }
  }
}

@keyframes appear {
  0% {
    width: 0;
  }
  100% {
    width: 190px;
  }
}
