@import "./assets/css/normalize.css";
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap');

:root * {

  /* fonts */

  --font-fam-main: "Barlow", sans-serif;
  --font-fam-alt: "Barlow Semi Condensed", sans-serif;
  --font-fam-cond: "Barlow Condensed", sans-serif;
  --font-fam-deco: "Playfair Display", serif;
  --font-fam-art: "Julius Sans One", sans-serif;
  --font-fam-cur: "Great Vibes", sans-serif;
  
  --color-primary: #1f80ff;
  --color-link: #1f80ff;
  --color-var: #3eb93e;

  /* color palettes */

  --color-brightest-green: #00cc00;
  --color-bright-green: #3eb93e;
  --color-darkest-blue: #000a2b;

  --color-brightest-red: crimson;

  --color-next-to-white: #f3f5f7;
  --color-neutral-lightest: #eee;
  --color-neutral-light: #ccc;
  --color-neutral-light-medium: #a0a2a6;
  --color-neutral-medium: rgb(91, 98, 110);
  --color-neutral-dark: rgb(45, 49, 59);
  --color-neutral-darkest: rgb(30, 30, 30);

  --color-next-to-black: #1f3d32;

  --color-white: white;
  --color-black: black;

  /* borders */

  --border-black: 1px solid black;
  --border-white: 1px solid white;
  --border-light: 1px solid #ccc;

  /* shadows */

  --base-shadow: 0 1px 2px 0 rgba(58, 59, 73, 0.2),
    0 3px 8px 0 rgba(58, 59, 73, 0.05);
  --max-shadow: 0 10px 25px rgba(138, 153, 165, 0.5);

}

:root * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  font-family: var(--font-fam-main);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.414;
  color: var(--color-neutral-dark);
  -webkit-font-smoothing: antialiased;
}

#root {
  min-height: 100vh;
}

.app {
  width: 100%;
  min-height: 100vh;
  font-size: 16px;
  font-size: 100%;
  font-size: 1rem;
  line-height: 1.333;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-fam-alt);
  font-weight: 400;
}

a {
  color: black;
  text-decoration: none;
}

.link {
  color: black;
}

.link:hover {
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}


textarea {
  resize: none; 
}