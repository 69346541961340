$base_shadow: 0 1px 2px 0 rgba(58, 59, 73, 0.2),
0 3px 8px 0 rgba(58, 59, 73, 0.05);
$base_shadow_2: 0 2px 7px 0 rgba(58, 59, 73, 0.2),
0 7px 14px 0 rgba(58, 59, 73, 0.1);
$base_border: 1px solid #e2e5e7;
$base_border_2: 1px solid #aaa;
$base_radius: 3px;


h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 2rem;
}

.box {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.bg {
  background-size: cover;
  background-repeat: no-repeat;
}

// buttons

.lt-btn {
  position: relative;
  border: 0;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  background-color: crimson;
  cursor: pointer;

  &.xs {
    padding: 4px 8px;
    font-size: 0.675rem;
  }

  &.s {
    padding: 0.4rem 1rem;
    font-size: 0.75rem;
  }

  &.m {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;

    &:hover {
      background-color: #000;
    }
  }

  &.l {
    padding: 1rem 3rem;
    font-size: 1.125rem;
  }

  &:hover {
    color: black;
    background-color: #1f80ff;
  }

  &:active {
    transform: scale(0.95);
  }

  &:focus {
    outline: none;
  }

  &.neutral {
    background-color: var(--color-neutral-light);
    color: black;

    &:hover {
      color: white;
      background-color: var(--color-brightest-red);
    }
  }

  &.hollow {
    color: var(--color-neutral-medium);
    border: 1px solid var(--color-neutral-medium);
    background-color: white;

    &:hover {
      background-color: var(--color-neutral-medium);
      color: white;
    }
  }

  &:hover {
    color: white;
    background-color: var(--color-brightest-red);
  }

  &.prima {
    background-color: black;
    color: white;

    &.bordered {
      border: 1px solid black;
    }

    &:hover {
      color: white;
      background-color: var(--color-brightest-red);
    }
  }

  &.alt {
    background-color: aquamarine;
    color: black;

    &:hover {
      background-color: #000;
      color: white;
    }

  }

  &.inverted {
    background-color: black;

    &:hover {
      background-color: var(--color-brightest-red);
    }
  }
}

.hide {
  display: none;
}


.mm-logo {
  .st0 {
    fill: #E2761B;
    stroke: #E2761B;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .st1 {
    fill: #E4761B;
    stroke: #E4761B;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .st2 {
    fill: #D7C1B3;
    stroke: #D7C1B3;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .st3 {
    fill: #233447;
    stroke: #233447;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .st4 {
    fill: #CD6116;
    stroke: #CD6116;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .st5 {
    fill: #E4751F;
    stroke: #E4751F;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .st6 {
    fill: #F6851B;
    stroke: #F6851B;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .st7 {
    fill: #C0AD9E;
    stroke: #C0AD9E;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .st8 {
    fill: #161616;
    stroke: #161616;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .st9 {
    fill: #763D16;
    stroke: #763D16;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}


.cookie-notice {
  ._1udoG {
    position: fixed;
    z-index: 80;
    background-color: black;
    color: white;

    @media (min-width: 923px) {
      width: 90%;
      margin: 0 5%;

      ._2XtTe,
      ._1OJEx {
        display: inline-flex;
        flex: 0 1 auto;
      }
    }

    @media (min-width: 1200px) {
      width: 1100px;
      margin: 0 calc(100% - 1100px / 2);
    }

    ._2sWcN {
      @media (min-width: 923px) {
        padding: 0.5rem 1rem;
        display: inline-flex;
        flex: 0 1 15%;
      }
    }

    ._1udoG>._2sWcN {
      color: white;

      @media (min-width: 923px) {
        flex-direction: row;
        width: 85%;
        display: inline-flex;

        ._2XtTe {
          flex: 0 1 15%;
        }

        ._1OJEx {
          display: inline-flex;
          flex: 0 1 85%;
          width: 85%;
          padding-right: 1rem;
        }

      }
    }

    ._1udoG>._3P9Kz {
      @media (min-width: 923px) {
        display: inline-flex;
        height: 100%;
        width: 15%;
        justify-content: flex-end;
        justify-self: flex-end;
        padding: 1rem 1rem 0 1rem;
        align-items: center;
      }
    }

    button {
      text-transform: uppercase;
      font-size: 0.75em;
    }

    ._1udoG>._3P9Kz>._ko_Hb:first-child,
    ._1udoG>._3P9Kz>._ko_Hb:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: aquamarine;
      border-color: #333;
    }
  }
}
