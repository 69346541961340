.letter-dance {
  display: block;
  position: relative;
  width: 500vw;
  z-index: 2;
  overflow: hidden;
  color: white;
  padding: 1rem 0 0 0;

  @media (min-width: 923px) {
    padding: 3rem 0 2rem;
  }

  .letterize {
    position: relative;
    display: flex;
    height: 100%;
    font-size: 1rem;
    font-family: var(--font-fam-semi);
    hyphens: none;
    white-space: nowrap;
    overflow: hidden;
    animation: lettertrain 60s linear infinite forwards;

    .line {
      position: relative;
      display: flex;
      flex: 0 1 auto;
      transition: 0.35s;
    }

    .word {
      display: inline-block;
      transition: 0.35s;
      margin-right: 0.25rem;

      &.spot:hover {
        display: block;
        text-align: center;
        flex: 0 0 10rem;
        width: 10rem;
        max-height: 4rem;
        padding-top: 1rem;
        color: aquamarine;
        cursor: pointer;
        opacity: 1;
        border: 1px solid aquamarine;
        border-radius: 7px;
        font-family: var(--font-fam-deco);
        font-size: 1.25rem;
        background-color: rgba(2, 187, 175, 0.2);
        transition: 0.35s;

        .letter {
          display: inline;
        }
      }
    }

    .letter {
      display: inline-block;
      flex: 0 0 1rem;
      width: 1rem;
      transition: 0.35s;
    }
    
    &.by-word {

      .line {
        flex-direction: column;
      }

      .word {
        margin-right: 2rem;
      }

      .letter {
        display: inline;
      }
    }
  }
}

@keyframes lettertrain {
  to {
    transform: translateX(-200vw);
  }
}
