.page-footer {
  width: 100%;
  display: flex;
  height: 96px;
  flex: 0 0 auto;
  align-items: center;
  background-color: black;
  padding: 1rem;
  font-size: 0.75rem;
  color: white;

  @media (min-width: 923px) {
    max-width: 1140px;
    align-items: center;
    justify-content: space-between;
    height: 128px;
    margin: 2rem auto;
    background-color: white;
    color: black;
  }
  @media (min-width: 1140px) {
    padding-bottom: 2rem;
  }

  .footer__nav {
    display: flex;
    align-items: center;
  }


  span.col {
    display: inline-block;
    padding: 0 1rem 0 0;
  }

  a.line {
    display: inline-block;
    color: white;
    padding: 0 1rem 0 0;

    @media (min-width: 923px) {
      color: black;
    }

    &:hover {
      color: crimson;
    }
  }

  .smm-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border: 0;
    color: white;
    margin-right: 0.5rem;

    svg {
      display: inline-flex;
      width: 1.5rem;
      height: 1.5rem;
      color: white;

      &:hover {
        cursor: pointer;
      }
    }

    &.twitter {

      svg {
        color: #1A8CD8;
      }
    }
  }
}