.single--wrapper {
  width: 100vw;
  background-color: white;

  @media (min-width: 923px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem 0;
  }

  .content-wrapper {

    @media (min-width: 923px) {
      display: flex;
      align-items: stretch;
      max-width: 1140px;
    }

    @media (min-width: 2280px) {
      &.wls-open {
        margin: 0 0 1rem auto;
      }
    }

  }

  .single-context {
    display: flex;

    @media (min-width: 923px) {
      flex-direction: column;
      max-width: 1140px;
    }
  }

  .single {
    width: 100%;
    padding: 1rem;

    @media (min-width: 923px) {
      flex: 0 0 calc(760px + 6rem);
      width: calc(760px + 6rem);
      margin-right: 1rem;
    }

    .item-title {
      font-family: var(--font-fam-deco);
      font-weight: 500;
      margin: 1rem 0;
      line-height: 1;
      color: crimson;
      font-size: 1.5rem;

      @media (min-width: 923px) {
        font-size: 2rem;
        margin: 1.5rem 0 2rem;
        padding-left: 0;
      }
    }


    .description {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      text-align: left;
      letter-spacing: 0;

      &::first-letter {
        text-transform: uppercase;
      }

      strong {
        font-family: var(--font-fam-deco);
        font-size: 1.5rem;

        @media (min-width: 923px) {
          font-family: var(--font-fam-deco);
        }
      }

      .text {
        display: inline-block;
      }

    }

    .thumbnail {
      width: 100%;
      height: auto;
    }

  }

  .create {
    flex: 1 1 auto;
    flex-direction: column;
    padding-left: 1rem;
    width: calc(1140px - 760px - 7rem);
    margin-bottom: 1rem;

    .feature-icon {
      display: none;

      @media (min-width: 923px) {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        height: auto;
        padding: 0;

        img {
          display: inline-block;
          width: 60%;
          height: 60%;
        }
      }
    }
  }

  hr {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem 0 1rem;
  }

}

.react-share__ShareButton {
  display: flex;
  margin: 0 0.5rem 0.5rem 0;
}

.share {
  width: 110px;
  flex: 0 0 110px;

  strong {
    font-family: var(--font-fam-deco);
    font-size: 1.5rem;

    @media (min-width: 923px) {
      font-family: var(--font-fam-deco);
    }
  }

  .icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}

.postmaker {
  margin: 0 auto;
  max-width: 1140px;

  .post-title {
    margin: 0 0 1rem 0;
  }
}


.single-page .loader-but-top {
  background-color: #111;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5vh;
}