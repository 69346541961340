.vertical .controls {
    width: 100vw;
}
.controls {
  position: relative;
  display: flex;
  //flex: 1 0 auto;
  height: 70px;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  color: white;
  width: 100%;
  z-index: 80;
  padding: 0 0.75rem;

  a {
    display: inline-flex;
    color: white;

    &:hover {
      color: crimson;
    }
  }

  .group {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-right: 0.5rem;

    &:last-of-type {
      border-left: 0;
      padding-right: 1rem;
    }

    &.menu {
      display: none;
    }
  }

  .dz {
    position: relative;
    display: inline-flex;
    width: 33px;
    height: 33px;
    margin-left: 0.25rem;

    &:hover {
      cursor: pointer;
    }

    &__area {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      z-index: 40;
    }

    &__label {
      display: none;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 20;
    }
  }

  .lt-btn {
    margin: 0 0 0 0.25rem;
    padding: 6px 7px;
  }

  .btn-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 4px;

    &:hover {
      cursor: pointer;
      color: aqua;

      &:hover {
        cursor: pointer;
        color: aqua;
      }

    }

    &.icon-download {
      margin-right: 8px;
    }

    svg {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 2px;
    }

    &.image svg {
      position: relative;
      width: 32px;
      height: 32px;
    }

    &.download svg {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }


  .font-size-spinner {
    display: inline-flex;
    margin: 0 0.75rem 0 0.75rem;

    @media (min-width: 923px) {
      margin: 0 1rem;
    }

    .spinner {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 1px solid white;
      height: 24px;
      width: 24px;
      font-size: 0.75rem;
      color: white;

      @media (min-width: 400px) {
        height: 24px;
        width: 24px;
        font-size: 0.875rem;
      }

      &.letter {
        font-weight: 700;
        color: white;
        background-color: black;

        &.black {
          color: black;
          background-color: white;

          &:hover {
            cursor: pointer;
            color: white;
            background-color: black;
          }
        }

        &.white {
          color: white;
          background-color: black;
          border: 1px solid white;

          &:hover {
            cursor: pointer;
            color: black;
            border: 1px solid white;
            background-color: white;
          }
        }
      }

      &.plus,
      &.minus {
        color: white;
        font-weight: 600;

        &:hover {
          cursor: pointer;
          color: aqua;
        }
      }

      &.plus {
        border-left: 0;
      }

      &.minus {
        border-right: 0;
      }
    }
  }

  @media (min-width: 923px) {

    .logo-img {
      display: inline-flex;
      margin: -2px .5rem 0 0;
      width: 50px;
      height: 50px;
    }

    .group {
      position: relative;
      display: inline-flex;
      align-items: center;

      &:first-of-type {
        border-left: 1px solid #777;
        padding-left: 1rem;
      }

      &.menu {
        display: inline-flex;
      }
    }

    .dz {
      position: relative;
      top: -1px;
      width: 33px;
      height: 33px;
      margin-right: 0.5rem;
    }

    .btn-icon {
      position: relative;
      width: 30px;
      height: 30px;

      svg {
        width: 30px;
        height: 30px;
      }

      &.color-toggle {
        width: 24px;
        height: 24px;
        border: 1px solid white;

        &:hover {
          cursor: pointer;
          border: 2px solid aqua;
        }
      }
      &.icon-refresh {
        position: relative;
        left: -4px;
      }

      &.icon-draft svg,
      &.icon-edit svg {
        width: 25px;
        height: 25px;
      }

      &.icon-edit.attention {
        color: aqua;
        animation: attention 1s 5;
      }

      @keyframes attention {
        0% {
          transform: scale(1);
          color: aqua;
        }
        50% {
          transform: scale(1.1);
          color: crimson;
        }
        100% {
          transform: scale(1);
          color: white;
        }
      }

      &.icon-image {
        width: 34px;
        height: 34px;

        &:hover {
          color: aqua;
        }
      }

      &.icon-download {
        width: 30px;
        height: 30px;

        &:hover {
          color: aqua;
        }
      }

    }

    .spinner {
      height: 30px;
      width: 30px;
    }
  }
  .btn-icon.icon-nft {
    position: relative;
    top: -4px;
    img {
      width: 36px;
      height: 36px;
    }
  }

  .drafts {
    position: absolute;
    top: calc(100vh - 70px - 100px);
    width: 100%;
    left: 1rem;
    z-index: 110;  
  }

}


.edit .szh-menu {

  @media (min-width: 923px) {
    position: relative;
    display: inline-flex;
    width: auto;
    background-color: black !important;
    z-index: 99;
  }
}

.szh-menu {
  background-color: black !important;
}

.szh-menu-button {
  position: relative;
  display: flex;
  align-items: center;
  background-color: black;
  color: white;
  border: 0;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 60;
}


.fip {
  position: relative;
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  flex: 1 0 50%;
}

  .logo {
    position: relative;
    display: inline-flex;
    width: 100%;
    align-items: center;
    font-family: 'Great Vibes', sans-serif;
    font-size: 2rem;
    font-weight: 400;
    color: white;

    .logo-img {
      display: inline-flex;
      margin: -2px 0 0 0;
      width: 50px;
      height: 50px;
    }

    .word-play {
      display: none;

      @media (min-width: 1200px) {
        display: inline-flex;
        font-family: 'Great Vibes', sans-serif;
        font-size: 2rem;
        font-weight: 400;
        color: white;
        margin-left: 0.5rem;
        padding-left: 1.25rem;
        border-left: 1px solid #666;
      }

    }

    a {
      display: inline-flex;
      color: white;

      &:hover {
        color: crimson;
      }
    }

    @media (min-width: 923px) {
      font-size: 2rem;
    }

    @media (min-width: 1200px) {
      font-size: 2rem;
    }

  }
