.burg-menu {
  position: relative;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 190px;
  z-index: 80;

  .submenu {
    padding-left: 2rem;
  }

  &__item {
    display: inline-flex;
    width: 100%;
    padding: 0.5rem 1rem;
    border: 0;
    border-bottom: 1px solid rgba(255,255,255,0.25);
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.618;
    text-transform: uppercase;
    color: var(--color-white);
    background-color: transparent;

    &.subtitle {
      border-top: 1px solid rgba(255,255,255,0.25);
      border-bottom: 0;
      cursor: default;
    }

    &:last-of-type {
      border-bottom: 0;
    }

    a {
      text-decoration: none;
      color: var(--color-white);

      &:hover {
        color: crimson
      }  
    }

    &:hover {
      cursor: pointer;
      color: crimson
    }

    &--nolink {
      display: inline-flex;
      width: 100%;
      padding: 0.5rem 1rem;
      border: 0;
      border-bottom: 1px solid rgba(255,255,255,0.25);
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.618;
      text-transform: uppercase;
      background-color: transparent;
      color: aquamarine;

      &:hover {
        cursor: default;
      }
    }
    &--nocaps {
      display: inline-flex;
      width: 100%;
      padding: 0.5rem 1rem 0;
      border: 0;
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 1.618;
      background-color: transparent;
      color: lightblue;

      &:hover {
        cursor: default;
      }
    }
  }
  .copy-icon {
    display: inline-block;
    background-color: transparent;
    border-radius: 0;
    border: 0;
    width: 1rem;
    height: 1rem;
    
    svg.btn-icon {
      position: relative;
      margin-left: 1rem;
      top: -0.5rem;
      display: inline-block;
      background-color: transparent;
      border-radius: 0;
      border: 0;
      width: 1rem;
      height: 1rem;
      padding: 0;
    }
  }
}


